import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import classnames from 'classnames'
import Image from 'next/image'

const YouTubeEmbed = ({
  videoId,
  width,
  height,
  aspectWidth = 16,
  aspectHeight = 9,
  imgSize = 'maxresdefault',
  title,
  priority = false,
  center = false,
  size = null,
}) => {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <div className="row">
      <div
        className={classnames({
          'col-md-6 offset-md-3': size === 'sm',
          'col-md-8 offset-md-2': size === 'md',
        })}
      >
        <div
          className={`ratio ratio-${aspectWidth}x${aspectHeight} mb-3`}
          onClick={() => setShowVideo(true)}
        >
          {showVideo ? (
            <iframe
              width={width || '100%'}
              height={height || '400px'}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0`}
              allowFullScreen
            ></iframe>
          ) : (
            <>
              <Image
                className="rounded"
                src={`https://img.youtube.com/vi/${videoId}/${imgSize}.jpg`}
                width={1280}
                height={720}
                priority={priority}
                alt="&nbsp;"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                  cursor: 'pointer',
                }}
              />
              {title && <h4 className="yt-title">{title}</h4>}
              <FontAwesomeIcon
                className="yt-icon"
                icon={faYoutube}
                color="red"
                size="5x"
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default YouTubeEmbed
